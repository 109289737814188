<template>
  <v-container fluid>
    <v-data-table
      :headers="headers"
      :items="flightlogs"
      item-key="tsflight"
      class="elevation-1"
      :search="search"
      sortBy="tsflight"
      :sort-desc="[true]"
    >
      <template v-slot:item.tsflight="{ item }">
        <span v-if="item.tsflight!==''">{{ new Date(item.tsflight+'+00:00').toLocaleString() }}</span>
      </template>

      <template v-slot:top>
        <v-toolbar flat>
        <v-toolbar-title>Orders/Flight Logs</v-toolbar-title>
        <v-spacer></v-spacer>
        <download-csv
	        :data   = "flightlogs"
          :fields = "csvfields"
          :labels = "csvlabels"
	        name    = "flightlogs.csv">
          <v-btn color="success" class="mr-6">
                Export CSV<i class="mdi mdi-export-variant" aria-hidden="true"></i>
          </v-btn>
        </download-csv>

        <v-text-field
          v-model="search"
          label="Search"
          class="mx-4"
        ></v-text-field>
        </v-toolbar>
      </template>
    </v-data-table>
    
  </v-container>
</template>

<script>
import { mapGetters, mapState } from "vuex"
import axios from 'axios'

export default {
  name: "FlightReportTable",
  components: {
  },
  computed: {
    ...mapGetters(["orders", "accessToken", "regionId"]),
  },
  data() {
    return {
      flightlogs: [],
      csvfields: [],
      csvlabels: [],
      headers:[{text:'Flight No.',value:'flight_id', width:"150px"},
               {text:'Date/Time', value:'tsflight', width:"200px"},
               {text:'Drone ID', value:'drone_id', width:"150px"},
               {text:'Battery No.', value:'battery_id', width:"150px"},
               {text:'Region', value:'region_id', width:"150px"},
               {text:'Pilot', value:'pilot_name', width:"150px"},
               {text:'Delivery Address', value:'delivery_address', width:"300px"},
               {text:'Delivery City', value:'delivery_city', width:"150px"},
               {text:'Delivery Zip', value:'delivery_zip'},
               {text:'Delivery Lat', value:'delivery_lat'},
               {text:'Delivery Lon', value:'delivery_lon'},
               {text:'Notes', value:'notes', width:"150px"},
               {text:'Takeoff Weight', value:'takeoff_weight'},
               {text:'Distance Flown', value:'distance_flown'},
               {text:'Max Altitude', value:'max_altitude'},
               {text:'Max Speed', value:'max_speed'},
               {text:'Flight Duration', value:'flight_duration'},
               {text:'Battery % used', value:'battery_used'},
               {text:'tsinitial', value:'tsinitial'},
               {text:'tsloaded', value:'tsloaded'},
               {text:'tssent', value:'tssent'},
               {text:'tshandoff', value:'tshandoff'},
               {text:'tsdropped', value:'tsdropped'},
               {text:'tsland', value:'tsland'}
               ],
      search: ''
    }
  },
  mounted() {
    this.query()
    for (const header of this.headers) {
      this.csvfields.push(header['value'])
      this.csvlabels.push(header['text'])
    }
    console.log(this.csvfields)
  },
  methods: {
    query() {
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.accessToken
      axios.get(window.apiurl+'flogquery')
        .then((response) => {
          this.flightlogs = response.data['flightlogs']
        })
        .catch((error) => console.log(error))

    }
  }
}
</script>
<style scoped>

</style>
