<template>
  <v-container fluid>
    <v-data-table
      :headers="headers"
      :items="orders"
      item-key="de_order_number"
      class="elevation-1"
      :search="search"
      sortBy="ts_created"
      :sort-desc="[true]"
    >
      <template v-slot:item.ts_created="{ item }">
        <span>{{ new Date(item.ts_created*1000.0).toLocaleString() }}</span>
      </template>
      <template v-slot:item.ts_assigned="{ item }">
        <span v-if="item.ts_assigned!==null">{{ new Date(item.ts_assigned*1000.0).toLocaleString() }}</span>
      </template>
      <template v-slot:item.ts_pickedup="{ item }">
        <span v-if="item.ts_pickedup!==null">{{ new Date(item.ts_pickedup*1000.0).toLocaleString() }}</span>
      </template>
      <template v-slot:item.ts_delivered="{ item }">
        <span v-if="item.ts_delivered!==null">{{ new Date(item.ts_delivered*1000.0).toLocaleString() }}</span>
      </template>

      <template v-slot:top>
        <v-toolbar flat>
        <v-toolbar-title>Orders/Flight Logs</v-toolbar-title>
        <v-spacer></v-spacer>
        <download-csv
	        :data   = "csvdata"
	        name    = "orders.csv">
          <v-btn color="success" class="mr-6">
                Export CSV<i class="mdi mdi-export-variant" aria-hidden="true"></i>
          </v-btn>
        </download-csv>

        <v-text-field
          v-model="search"
          label="Search"
          class="mx-4"
        ></v-text-field>
        </v-toolbar>
      </template>
    </v-data-table>
    
  </v-container>
</template>

<script>
import { mapGetters, mapState } from "vuex"
import axios from 'axios'

export default {
  name: "FullOrdersTable",
  components: {
  },
  computed: {
    ...mapGetters(["accessToken", "regionId"]),
  },
  data() {
    return {
      orders: [],
      csvdata:[],
      headers:[
               {text:'Date/Time', value:'ts_created', width:"200px"},
               {text:'Status', value:'status'},
               {text:'Active', value:'active'},
               {text:'Customer',value:'de_customer_code', width:"20px"},
               {text:'Reference Num',value:'reference_number', width:"150px"},
               {text:'Flight Num',value:'flight_id', width:"150px"},
               {text:'Origin Store Code', value:'origin_store_code'},
               {text:'Store Order Num', value:'store_order_number'},
               {text:'Drone ID', value:'drone_id', width:"150px"},
               {text:'Region', value:'region_id', width:"150px"},
               {text:'Delivery Address', value:'destination.full_address', width:"300px"},
               {text:'Delivery Lat', value:'destination.lat'},
               {text:'Delivery Lon', value:'destination.lng'},
               {text:'Distance', value:'destination.distance'},
               {text:'DA Time', value:'ts_assigned'},
               {text:'DPU Time', value:'ts_pickedup'},
               {text:'DD Time', value:'ts_delivered'}
               ],
      search: ''
    }
  },
  mounted() {
    this.query()
  },
  methods: {
    query() {
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.accessToken
      axios.get(window.apiurl+'orderquery')
        .then((response) => {
          this.orders = response.data['fullorders']
          for (let i=0; i<this.orders.length; i++) {
            let d = {}
            for (const header of this.headers) {
              // d[header['text']] = (this.orders[i][header['value']]!==undefined) ? this.orders[i][header['value']] : ''
              // d[header['text']] = this.orders[i][header['value']]
              if (header['value'].indexOf('.')!==-1) {
                let field = header['value'].replace('destination.','')
                d[header['text']] = this.orders[i].destination.[field]
              } else {
                d[header['text']] = this.orders[i][header['value']]
              }
            }
            this.csvdata.push(d)
            console.log(d)
          }

        })
        .catch((error) => console.log(error))

    }
  }
}
</script>
<style scoped>

</style>
