<template>
  <v-container fluid>
  <v-tabs v-model="tab">
    <v-tab>Orders</v-tab>
    <v-tab>Flight Logs</v-tab>
    <v-tab>Battery Logs</v-tab>
  </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item>
        <FullOrdersTable></FullOrdersTable>
      </v-tab-item>
      <v-tab-item>
        <FlightReportTable></FlightReportTable>
      </v-tab-item>
      <v-tab-item>
        <BatteryReportTable></BatteryReportTable>
      </v-tab-item>
    </v-tabs-items>

  </v-container>
</template>

<script>
import { mapGetters, mapState } from "vuex"
import axios from 'axios'
import BatteryReportTable from "../component/BatteryReportTable"
import FlightReportTable from "../component/FlightReportTable"
import FullOrdersTable from "../component/FullOrdersTable"

export default {
  components: {
      BatteryReportTable, FlightReportTable, FullOrdersTable
  },
  computed: {
    ...mapGetters(["orders", "accessToken", "regionId"]),
  },
  data() {
    return {
        tab:0
    }
  },
  mounted() {
  },
  methods: {
  }
}
</script>
<style scoped>

</style>
