<template>
  <v-container fluid>
    <v-data-table
      :headers="headers"
      :items="batterylogs"
      item-key="tsflight"
      class="elevation-1"
      :search="search"
      sortBy="tsflight"
      :sort-desc="[true]"
    >
      <template v-slot:item.tsflight="{ item }">
        <span v-if="item.tsflight!==''">{{ new Date(item.tsflight+'+00:00').toLocaleString() }}</span>
      </template>

      <template v-slot:top>
        <v-toolbar flat>
        <v-toolbar-title>Battery Log</v-toolbar-title>
        <v-spacer></v-spacer>
        <download-csv
	        :data   = "batterylogs"
	        name    = "batterylogs.csv">
          <v-btn color="success" class="mr-6">
                Export CSV<i class="mdi mdi-export-variant" aria-hidden="true"></i>
          </v-btn>
        </download-csv>

        <v-text-field
          v-model="search"
          label="Search"
          class="mx-4"
        ></v-text-field>
        </v-toolbar>
      </template>
    </v-data-table>
    
  </v-container>
</template>

<script>
import { mapGetters, mapState } from "vuex"
import axios from 'axios'

export default {
  name: "BatteryReportTable",
  components: {
  },
  computed: {
    ...mapGetters(["orders", "accessToken", "regionId"]),
  },
  data() {
    return {
      batterylogs: [],
      headers:[{text:'Flight No.',value:'flight_id'},
               {text:'Battery No.', value:'battery_id'},
               {text:'Type', value:'battery_type'},
               {text:'Distance Flown', value:'distance_flown'},
               {text:'Battery % Used', value:'battery_used'},
               {text:'Flight Duration', value:'flight_duration'},
               {text:'Date/Time', value:'tsflight'}],
      search: ''
    }
  },
  mounted() {
    this.query()
  },
  methods: {
    query() {
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.accessToken
      axios.get(window.apiurl+'batteries')
      .then((response) => {
        this.batteries = {}
        for (const bat of response.data['batteries']) {
          this.batteries[bat['battery_id']] = bat['type']
        }
        axios.get(window.apiurl+'flogquery')
        .then((response) => {
          let newlogs = response.data['flightlogs']
          this.batterylogs = []
          for (let i=0; i<newlogs.length; i++) {
            let d = newlogs[i]
            let bat = {}
            for (const header of this.headers) {
              if (header.value === 'battery_type') {
                bat[header.value] = this.batteries[d['battery_id']]
              } else {
                bat[header.value] = d[header.value]
              }
            }
            this.batterylogs.push(bat)
          }
        })
        .catch((error) => console.log(error))
      })
      .catch((error) => console.log(error))
    }
  }
}
</script>
<style scoped>

</style>
