var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.orders,"item-key":"de_order_number","search":_vm.search,"sortBy":"ts_created","sort-desc":[true]},scopedSlots:_vm._u([{key:"item.ts_created",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(new Date(item.ts_created*1000.0).toLocaleString()))])]}},{key:"item.ts_assigned",fn:function(ref){
var item = ref.item;
return [(item.ts_assigned!==null)?_c('span',[_vm._v(_vm._s(new Date(item.ts_assigned*1000.0).toLocaleString()))]):_vm._e()]}},{key:"item.ts_pickedup",fn:function(ref){
var item = ref.item;
return [(item.ts_pickedup!==null)?_c('span',[_vm._v(_vm._s(new Date(item.ts_pickedup*1000.0).toLocaleString()))]):_vm._e()]}},{key:"item.ts_delivered",fn:function(ref){
var item = ref.item;
return [(item.ts_delivered!==null)?_c('span',[_vm._v(_vm._s(new Date(item.ts_delivered*1000.0).toLocaleString()))]):_vm._e()]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Orders/Flight Logs")]),_c('v-spacer'),_c('download-csv',{attrs:{"data":_vm.csvdata,"name":"orders.csv"}},[_c('v-btn',{staticClass:"mr-6",attrs:{"color":"success"}},[_vm._v(" Export CSV"),_c('i',{staticClass:"mdi mdi-export-variant",attrs:{"aria-hidden":"true"}})])],1),_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"Search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }